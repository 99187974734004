<template>
  <div>
    <!-- <button class="btn btn-primary m-2" @click="tryPickup">Try Pickup</button> -->
    <h3>Set Langganan untuk User:</h3>
    <p>Nama: {{ $store.state.userDetail.fullName }}</p>
    <p>Phone: {{ $store.state.userDetail.phoneNumber }}</p>
    <div class="m-5">
      <form @submit.prevent="activateSubscription">
        <label for="datepicker">Set tanggal mulai berlangganan</label>
        <b-form-datepicker
          required
          id="datepicker"
          v-model="startDate"
          class="mb-2"
        ></b-form-datepicker>
        <label for="datepicker2">Set tanggal akhir berlangganan</label>
        <b-form-datepicker
          required
          id="datepicker2"
          v-model="endDate"
          class="mb-2"
        ></b-form-datepicker>
        <label for="imagepicker">Masukkan bukti transfer</label>
        <b-form-file
          id="imagepicker"
          v-model="imageFile"
          @change="onFileChange"
          class="mt-3"
          plain
        ></b-form-file>
        <button class="btn btn-primary m-2" type="submit">Set Aktif</button>
        <div class="mt-3">
          <img v-if="imageUrl" :src="imageUrl" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  data() {
    return {
      startDate: "",
      endDate: "",
      imageFile: null,
      imageUrl: null,
      file: null,
    };
  },
  mounted() {
    if (this.$store.state.userDetail.id !== this.$route.params.userId) {
      this.$store.dispatch("fetchUser", { userId: this.$route.params.userId });
    }
  },
  methods: {
    activateSubscription() {
      if (!this.imageFile) {
        return alert("jangan lupa input bukti transfer");
      } else {
        let data = new FormData();
        data.append("subscriptionStatus", true);
        data.append("subscriptionEnd", this.endDate);
        data.append("subscriptionStart", this.startDate);
        data.append("amount", 50000);
        data.append("transactionDetail", "Admin");
        data.append("image", this.file);
        data.append("UserId", this.$route.params.userId);

        axios({
          method: "POST",
          // url: `https://dev-wallet.mountrash.online/wallet/admin`,
          url: `https://mtps-wallet.mountrash.online/wallet/admin`,
          headers: {
            access_token: localStorage.getItem("token"),
          },
          data,
        })
          .then(() => {
            this.$router.push({
              name: "User",
              params: { userId: this.$route.params.userId },
            });
          })
          .catch((err) => {
            console.log(err.response);
            return alert("ada yang salah sepertinya", err.response.data);
          });
      }
    },
    tryPickup() {
      let data = new FormData();
      data.append("CustomerUserId", "4b340d50-ae19-11eb-bad9-b7de8950bf9d");
      data.append("Point", 10);
      axios({
        method: "POST",
        // url: `https://dev-picker.mountrash.online/pickup`,
        url: `https://picker.mountrash.online/pickup`,
        headers: {
          access_token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZU51bWJlciI6Iis2MjgxMzI5MTQzNTQ1IiwibmFtZSI6bnVsbCwiVXNlcklkIjoiYTY5ODZmOTAtYWUxYi0xMWViLWJiN2MtNzc0MjY3YTQ5ZTc2IiwiUm9sZSI6Im10cHNhbm9ueW1vdXMiLCJpYXQiOjE2MjAyODE3MjB9.AtUUzAdLbWktc2AVwj55WjaAOx1iEbUlyzdEG5cjPTc",
        },
        data,
      })
        .then((response) => {
          console.log("response > ", response);
        })
        .catch((err) => {
          console.log("err response > ", err.response.data);
        });
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.imageUrl = URL.createObjectURL(this.file);
    },
  },
};
</script>

<style>
</style>